<template>
  <div>
    <h3 ref="def">
      Constituents of Atoms: Electrons, Protons & Neutrons
    </h3>
    <p>
      The atoms are made of three types of subatomic particles: electrons, protons and neutrons.
      Electrons are denoted by symbol \(e\), and are negatively charged particles.  Protons are denoted by symbol \(p\), and are positively
      charged particles.
      Neutrons are denoted by symbol \(n\), are and electrically neutral.<br>
      The core of the atoms &mdash; also known as the nucleus &mdash; is made of protons and neutrons.
      The electrons are arranged around the nucleus in various orbitals.
      The subatomic particles are responsible for a number of key properties of the atoms.
      For example:
    </p>
    <ul style="list-style-type:square;">
      <li>
        <h5>Atomic Number (Z)</h5>
        The atomic number (Z) of an atom is given by the number of protons in its nucleus.
      </li>
      <li>
        <h5>Electric Charge</h5>
        The number of electrons in an atom that is neutral is equal to the number of protons in the atom.
        When the number of electrons is not same as the number of protons, there is a net charge on the atom.
      </li>
      <li> <h5>Atomic Mass</h5> The sum of the masses of protons and neutrons in the nucleus of the atoms is equal to the atomic mass of the atom.</li>
    </ul>
    <br>
    <h3 ref="rvsp">
      Electronic Structure
    </h3>
    <p>
      Electronic configuration refers to the arrangement of electrons in atoms.
      Electrons are arranged in the order of increasing energy levels. Each such level is called an orbital.
    </p><h3 ref="bd">
      Bohr's Diagram of Atom
    </h3>
    <p>
      Bohr's model was proposed by Danish physicist Neils Bohr in the year 1913. Bohr's model was one of the earliest model
      to describe the structure of atom. According to Bohr's model, an atom consists of a
      tiny positively charged heavy core, called a nucleus. The nucleus is surrounded by light, negatively charged
      electrons revolving in circular orbits. These orbits are called shells. This model is very similar to the planets orbiting the sun.
    </p>
    <h3>Features of Bohr's Model</h3>
    <ul style="list-style-type:square;">
      <li>
        Electrons revolve around the nucleus in circular orbits or shells that have set size and energy.
        The electrons are bound to these shells by electrostatic forces.
      </li>
      <li> Each shell corresponds to an energy level. The shells are labeled alphabetically with the letters K, L, M, N, … </li>
      <li>
        Each shell can contain only a fixed number of electrons. The general rule is that the nth shell can contain
        a maximum of \(2n^2\) electrons. Thus
        The first shell (K) can hold a maximum of 2 electrons.  The second shell (L) can hold up to 8 electrons.
        The third shell (M) can contain a maximum of 18 electrons and so on.
      </li>
      <li>
        Electrons occupy the orbits in the order of increasing
        energy level. The lowest energy electrons occupy the smallest orbit.
      </li>
      <li> Energy is absorbed or emitted whenever an electron moves from one orbit to another.</li>
    </ul>
    </p>
    <h3 ref="pg">
      MagicGraph: Bohr Diagrams of Atoms
    </h3>
    <p>
      Use this MagicGraph to generate Bohr diagrams for the first 36 elements of the periodic table &mdash; From Hydrogen (atomic symbol: <b>H </b>) to Krypton (atomic symbol: <b>Kr</b>).
    </p>
    <h5>To Get Started</h5>
    <p>
      Enter the atomic number (Z) of the element for which the Bohr diagram needs to be created.
      Based on the value provided, the MagicGraph will then &mdash;
    </p>
    <ul class="a">
      <li>
        Provide you the element name and properties.
      </li>
      <li>
        Generate the Bohr's diagram for the element.
      </li>
      <li> Calculate the electronic occupancy in various shells (i.e. K, L, M, N ...).</li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ElectronicStructure',
  components: {
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
      topics: [
      {
        headline: "Ed from Edliy", description: 'Hi! I am Ed from Edliy, and I created this PlayGraph to help you learn how to balance a chemical equation. Start with entering the coefficients for each of the chemical species in the equation. As you enter the coefficients, the PlayGraph will automatically calculate the number of moles for each species on the reactant and the product side as a guide to help you with balancing the mass.',
        img:"assets/EdfromEdliy.svg", path: ""
      },
      ]
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Bohr\'s Model of Atoms';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Constituents of Atoms',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Electronic Structure', img:'/assets/number-2.svg', action: () => this.goto('pg')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newChem =true;
    this.$store.commit('navigation/replaceChem', newChem);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);
}
#jxgbox1 input {border-style:solid;border-radius:4px;background-color:#FEBD49}
</style>
