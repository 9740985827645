const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-15, 16, 15,-14],keepaspectratio: true, axis:false, ticks:false, grid:false, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  brd1.options.layer['circle']=9;
  brd1.options.layer['image']=10;
	brd1.options.layer['point']=11;
  brd1.options.layer['text']=12;
  var resize = function () {
          brd1.resizeContainer(brd3.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
          brd1.fullUpdate();
          };
  window.onresize = resize;
  ///
	brd1.create('text', [0, 14, '<b>Bohr Diagram of Atoms </b>'], {fixed:true, anchorX:'middle', fontSize:function(){return 32*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('text', [-8, 11, '<b>Enter the atomic number Z = </b>'], {fixed:true, anchorX:'left', fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	//brd1.create('text', [11, 0, '<b>Z = </b>'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	var Let = brd1.create('input', [5, 11, "2", ' '], {cssStyle: 'fontFamily:Oswald;width:5%;', fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}, fixed:true, parse:true});
	//var go =brd1.create('image', ['/assets/go.svg', [14.5, -0.75],[1.5,1.5]],{fixed:true});
	var ZZ =2;
  var aa=1;
	var SS = [2, 8, 8, 18];
	var CC=[0, 0, 0, 0, 0, 0];
	var Met = ['H', 'He', 'Li', 'Be', 'B', 'C', 'N', 'O', 'F', 'Ne', 'Na', 'Mg', 'Al', 'Si', 'P','S', 'Cl', 'Ar', 'K', 'Ca', 'Sc', 'Ti', 'V', 'Cr', 'Mn', 'Fe', 'Co', 'Ni', 'Cu', 'Zn', 'Ga', 'Ge', 'As', 'Se', 'Br', 'Kr' ]
  var name = ['Hydrogen', 'Helium', 'Lithium', 'Beryllium', 'Boron', 'Carbon', 'Nitrogen', 'Oxygen', 'Fluorine', 'Neon', 'Sodium', 'Magnesium', 'Aluminium', 'Silicon', 'Phosphorus','Sulphur', 'Chlorine', 'Argon', 'Potassium', 'Calcium', 'Scandium', 'Titanium', 'Vanadium', 'Chromium', 'Manganese', 'Iron', 'Cobalt', 'Nickel', 'Copper', 'Zink', 'Gallium', 'Germanium', 'Arsenic', 'Selenium', 'Bromine', 'Krypton' ]
  var run = function() {
			if (ZZ/SS[0] > 1)
			{
            CC[0] = SS[0];
			aa +=1;
			}
			else{
			CC[0] = Math.max(ZZ,0);
			}
			ZZ = ZZ-SS[0]
			if (ZZ/SS[1] > 1)
			{
            CC[1] = SS[1];
			aa +=1;
			}
			else{
			CC[1] = Math.max(ZZ,0);
			}
			ZZ = ZZ-SS[1]
			if (ZZ/SS[2] > 1)
			{
            CC[2] = SS[2];
			aa +=1;
			}
			else{
			CC[2] = Math.max(ZZ,0);
			}
			ZZ = ZZ-SS[2]
			if (ZZ/SS[3] >=1)
			{
            CC[3] = SS[3];
			aa +=1;
			}
			else{
			CC[3] = Math.max(ZZ,0);
			}
			ZZ = ZZ-SS[3]
}
  //brd1.on('move', function(){ZZ=Let.Value(); aa=1; run()});
	var cl =brd1.create('image', ['/assets/moon.svg', [-0.75, -0.75],[1.5,1.5]],{fixed:true});
	//brd1.create('text', [0, 0, function(){return ''+ Met[Let.Value()-1]}], {anchorX:'middle', fixed:true, fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('image', ['/assets/shape.svg', [-3., -12.25],[2.,2.]],{fixed:true});
  var nuc =brd1.create('image', ['/assets/Nan.svg', [-1., -1],[2.,2.]],{fixed:true});
  nuc.setLabel('Nucleus')
  nuc.label.setAttribute({visible:false, anchorX:'middle', offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:16});
  nuc.on('over', function () {this.label.setAttribute({visible:true});});
  nuc.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////
	brd1.create('text', [-2., -11.25, function(){return ''+ Met[Let.Value()-1]}], {fixed:true, anchorX:'middle', anchorY:'middle', fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [-6., -11.25, function(){return ''+ name[Let.Value()-1]}], {fixed:true, anchorX:'middle', anchorY:'middle', fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 1, -9, 'K'], {color:'red', display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 3, -9, 'L'], {color:'blue', display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 5, -9, 'M'], {color:'green',display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 7, -9, 'N'], {color:'orange',display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
////////////////////////////////
  brd1.create('segment', [[0.25 ,-8],[0.25, -13]], {strokeColor:'grey', strokeWidth:1, dash:1, fixed:true});
  brd1.create('segment', [[2.25 ,-8],[2.25, -13]], {strokeColor:'grey', strokeWidth:1, dash:1, fixed:true});
  brd1.create('segment', [[4.25 ,-8],[4.25, -13]], {strokeColor:'grey', strokeWidth:1, dash:1, fixed:true});
  brd1.create('segment', [[6.25 ,-8],[6.25, -13]], {strokeColor:'grey', strokeWidth:1, dash:1, fixed:true});
  brd1.create('segment', [[8.25 ,-8],[8.25, -13]], {strokeColor:'grey', strokeWidth:1, dash:1, fixed:true});
  ///////////////////////////////////
  brd1.create('text', [ 1, -11, function(){return ''+ CC[0]}], {display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 3, -11, function(){return ''+ CC[1]}], {display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 5, -11, function(){return ''+ CC[2]}], {display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  brd1.create('text', [ 7, -11, function(){return ''+ CC[3]}], {display:'internal', fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
///////////////////////////////////////////////////
  var Ncirc =brd1.create('circle', [[0,0],[8, 0]],{fixed:true, strokeColor:'orange', fillColor:'white', strokeWidth:4,visible: function(){if(aa>=4){return true}else{return false}}});
	var Mcirc =brd1.create('circle', [[0,0],[6, 0]],{fixed:true, strokeColor:'green', fillColor:'white', strokeWidth:4,visible: function(){if(aa>=3){return true}else{return false}}});
	var Lcirc = brd1.create('circle', [[0,0],[4, 0]],{fixed:true, strokeColor:'blue', fillColor:'white', strokeWidth:4, visible: function(){if(aa>=2){return true}else{return false}}});
	var Kcirc =brd1.create('circle', [[0,0],[2, 0]],{fixed:true, strokeColor:'red', fillColor:'white', strokeWidth:4, visible: function(){if(aa>=1){return true}else{return false}}});
  Kcirc.setLabel('K Shell')
  Kcirc.label.setAttribute({visible:false, anchorX:'middle', offset:[15, -20], CssStyle:'fontFamily:Oswald', fontSize:16});
  Kcirc.on('over', function () {this.label.setAttribute({visible:true});});
  Kcirc.on('out', function () {this.label.setAttribute({visible:false});});
  ////////////////////
  Lcirc.setLabel('L Shell')
  Lcirc.label.setAttribute({visible:false, anchorX:'middle', offset:[15, -20], CssStyle:'fontFamily:Oswald', fontSize:16});
  Lcirc.on('over', function () {this.label.setAttribute({visible:true});});
  Lcirc.on('out', function () {this.label.setAttribute({visible:false});});
  ///////////////
  Mcirc.setLabel('M Shell')
  Mcirc.label.setAttribute({visible:false, anchorX:'middle', offset:[15, -20], CssStyle:'fontFamily:Oswald', fontSize:16});
  Mcirc.on('over', function () {this.label.setAttribute({visible:true});});
  Mcirc.on('out', function () {this.label.setAttribute({visible:false});});
  /////////////////////////
  Ncirc.setLabel('N Shell')
  Ncirc.label.setAttribute({visible:false, anchorX:'middle', offset:[15, -20], CssStyle:'fontFamily:Oswald', fontSize:16});
  Ncirc.on('over', function () {this.label.setAttribute({visible:true});});
  Ncirc.on('out', function () {this.label.setAttribute({visible:false});});
  ////////////////////////////////////
  var L=[]; var M=[]; var N=[]; var O=[]; var i=0; var j=0; var k=0; var l =0;
  //brd1.create('point', [2*Math.cos(0*2*Math.PI/2),2*Math.sin(i*2*Math.PI/2)], {name:'', size:function(){if(i<CC[0]){return 4}else{return 0}}, color:'grey'});
  run();
	var run1 = function(){
	for(i=0; i<2; i++)
	{
//	L[i]= brd1.create('point', [2*Math.cos(i*2*Math.PI/2),2*Math.sin(i*2*Math.PI/2)], {name:'', size:0, color:'grey'});
  L[i]  = brd1.create('image',['/assets/minus.svg', [2*Math.cos(i*2*Math.PI/2)-0.25,2*Math.sin(i*2*Math.PI/2)-0.25], [0.5, 0.5]],{fixed:true, visible:false});
  }
	}
	var run2 = function(){
	for(j=0; j<8; j++)
	{
  M[j]  = brd1.create('image',['/assets/minus.svg', [4*Math.cos(j*2*Math.PI/8)-0.25,4*Math.sin(j*2*Math.PI/8)-0.25], [0.5, 0.5]],{fixed:true, visible:false});
	//M[j]=brd1.create('point', [4*Math.cos(j*2*Math.PI/8),4*Math.sin(j*2*Math.PI/8)], {name:'', size:0, color:'grey'});
	}
	}
	var run3 = function(){
	for(k=0; k<8; k++)
	{
	//N[k]=brd1.create('point', [6*Math.cos(k*2*Math.PI/8),6*Math.sin(k*2*Math.PI/8)], {name:'', size:0, color:'grey'});
  N[k]  = brd1.create('image',['/assets/minus.svg', [6*Math.cos(k*2*Math.PI/8)-0.25,6*Math.sin(k*2*Math.PI/8)-0.25], [0.5, 0.5]],{fixed:true, visible:false});

  }
	}
	var run4 = function(){
	for(l=0; l<18; l++)
	{
	//O[l]=brd1.create('point', [8*Math.cos(l*2*Math.PI/18),8*Math.sin(l*2*Math.PI/18)], {name:'', size:0, color:'grey'});
  O[l]  = brd1.create('image',['/assets/minus.svg', [8*Math.cos(l*2*Math.PI/18)-0.25,8*Math.sin(l*2*Math.PI/18)-0.25], [0.5, 0.5]],{fixed:true, visible:false});
  }}
	run1(); run2(); run3(); run4();
	var gun1 = function(){
	for(i=0; i<2; i++)
	{
		L[i].setAttribute({visible:false})
	}
	for(i=0; i<CC[0]; i++)
	{
		L[i].setAttribute({visible:true})
	}}
	var gun2 = function(){
	for(i=0; i<8; i++)
	{
		M[i].setAttribute({visible:false})
	}
	for(i=0; i<CC[1]; i++)
	{
		M[i].setAttribute({visible:true})
	}
	}
	var gun3 = function(){
	for(i=0; i<8; i++)
	{
		N[i].setAttribute({visible:false});
	}
	for(i=0; i<CC[2]; i++)
	{
		N[i].setAttribute({visible:true});
	}
	}
	var gun4 = function(){
	for(i=0; i<18; i++)
	{
		O[i].setAttribute({visible:false});
	}
	for(i=0; i<CC[3]; i++)
	{
		O[i].setAttribute({visible:true});
	}
	}
  gun1(); gun2(); gun3(); gun4();
//go.on('down', function(){gun1(); gun2(); gun3(); gun4();});
  let magicGraph = document.querySelector("#jxgbox1");
  let inputFields = magicGraph.querySelectorAll("input");
  var onEachPress = function(event) {ZZ=Let.Value();aa=1;run();gun1();gun2();gun3();gun4()}
  inputFields.forEach(function(field) {
  field.addEventListener('input', onEachPress);});
	},}
export default Boxes;
